<template>
  <div class="relative noto page">
    <div class="relative page_img_box">
      <div class="page_img page_img01 relative"></div>
      <h1
        class="f-25 f-20-xxs f-18-xxxs fuchi_shadow absolute_xy inline-block page_titile"
      >
        不動産のご相談
      </h1>
    </div>
    <div class="area pt-6 pb-10 relative" ref="area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div>
        <div class="f-20 f-18-xs f-12-xxs">いずれをご検討されていますか？</div>
        <div class="mt-10 mb-16">
          <div class="disno-sm">
            <table class="relative-center inline-block">
              <tr>
                <td class="px-4">
                  <router-link to="#sale">
                    <v-btn
                      block
                      outlined
                      color="#EF6C00"
                      x-large
                      class="f-11 f-10-xxs box-shadow"
                      >売却</v-btn
                    >
                  </router-link>
                </td>
                <td class="px-4">
                  <router-link to="#buy">
                    <v-btn
                      block
                      outlined
                      color="#EF6C00"
                      x-large
                      class="f-11 f-10-xxs box-shadow"
                      >購入</v-btn
                    >
                  </router-link>
                </td>
                <td class="px-4">
                  <router-link to="#manage">
                    <v-btn
                      block
                      outlined
                      color="#EF6C00"
                      x-large
                      class="f-11 f-10-xxs box-shadow"
                      >管理</v-btn
                    >
                  </router-link>
                </td>
                <td class="px-4">
                  <router-link to="#use">
                    <v-btn
                      block
                      outlined
                      color="#EF6C00"
                      x-large
                      class="f-11 f-10-xxs box-shadow"
                      >活用</v-btn
                    >
                  </router-link>
                </td>
                <td class="px-4">
                  <router-link to="#etc">
                    <v-btn
                      block
                      outlined
                      color="#EF6C00"
                      x-large
                      class="f-11 f-10-xxs box-shadow"
                      >その他</v-btn
                    >
                  </router-link>
                </td>
              </tr>
            </table>
          </div>
          <div class="dis-sm">
            <v-row class="text-center px-4">
              <v-col cols="4">
                <router-link to="#sale">
                  <v-btn
                    block
                    outlined
                    color="#EF6C00"
                    large
                    class="f-11 f-10-xxs select_btn box-shadow"
                    >売却</v-btn
                  >
                </router-link>
              </v-col>
              <v-col cols="4">
                <router-link to="#buy">
                  <v-btn
                    block
                    outlined
                    color="#EF6C00"
                    large
                    class="f-11 f-10-xxs select_btn box-shadow"
                    >購入</v-btn
                  >
                </router-link>
              </v-col>
              <v-col cols="4">
                <router-link to="#manage">
                  <v-btn
                    block
                    outlined
                    color="#EF6C00"
                    large
                    class="f-11 f-10-xxs select_btn box-shadow"
                    >管理</v-btn
                  >
                </router-link>
              </v-col>
              <v-col cols="4">
                <router-link to="#use">
                  <v-btn
                    block
                    outlined
                    color="#EF6C00"
                    large
                    class="f-11 f-10-xxs select_btn box-shadow"
                    >活用</v-btn
                  >
                </router-link>
              </v-col>
              <v-col cols="4">
                <router-link to="#etc">
                  <v-btn
                    block
                    outlined
                    color="#EF6C00"
                    large
                    class="f-11 f-10-xxs select_btn box-shadow"
                    >その他</v-btn
                  >
                </router-link>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div id="sale" />
      <h2 class="f-20 f-16-xxs inline-block f-gray">売却をご検討のお客様</h2>
      <br />
      <h3 class="relative inline-block">
        <img src="@/assets/img/loupe.svg" class="absolute loupe_img z-1" />
        <div class="relative f-blue fuchi f-24 f-17-sm f-15-xs z-2">
          査定の精度・信頼度ならば <br class="dis-xs" />お任せください
        </div>
      </h3>
      <div class="z-1 relative-center w1200 point2 opacity0" ref="sec2">
        <v-row class="mb-16">
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <img src="@/assets/img/sale01.jpg" class="sale_img sale01" />
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <h4 class="f-18 f-16-sm f-14-xs f-12-xxs f-11-xxxs">
              精度の高い 信頼できる査定とは……？
            </h4>
            <div
              class="text-left f-11 line17 mt-6 px-2 area_text relative-center w600"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;たとえば、一括査定サイトを通じて5～10社の不動産事業者に見積を依頼したとしても査定額は、どこもほぼ横並びになります。同じAIソフトを使って、同じ内容を入力すれば、同じ査定金額が提示されるのは当然です<br /><br />

              &nbsp;&nbsp;&nbsp;&nbsp;比して、クレボの査定はAIによる簡易査定だけで終わりません。<br />むしろ、ここから先が腕前の見せどころ。他社が決してヒアリングしないような細部にまで目を配った信頼度の高い金額をお見積りさせていただきます
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-16">
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <h4 class="f-18 f-16-sm f-14-xs f-12-xxs">
              どんな家屋にも、必ず<br class="dis-xxs" />加点ポイントがある!!
            </h4>
            <div
              class="text-left f-11 line17 mt-6 px-2 area_text relative-center w600"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;誰しも、長く住んだ愛着のある家屋を、地域や築年数、面積、構造等、数項目のみの簡易計算で一律に評価されたいとは思わないはずです。其々の家屋が持つ固有の価値を、しっかりと見つけ出す作業もまた査定の大切な役割です<br /><br />
              &nbsp;&nbsp;&nbsp;&nbsp;どんな家屋にも、必ず加点ポイントが存在します。クレボでは、その家屋だけが持つ固有の加点ポイントを決して見逃がさないよう、100項目以上に及ぶ入念なチェックを行っています
            </div>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <img src="@/assets/img/sale02.jpg" class="sale_img sale01" />
          </v-col>
        </v-row>
      </div>
      <div ref="houses" class="houses relative flex-center">
        <img
          :src="require('@/assets/img/house' + i + '.svg')"
          class="house px-4 float-left opacity0"
          :class="{ house_m: houses[i] }"
          v-for="i in 16"
          :key="i"
        />
        <div class="clear" />
      </div>

      <div class="relative-center w1300 opacity0" ref="sec3">
        <v-row ref="papers ">
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <img src="@/assets/img/sale03.png" class="sale03 relative" />
          </v-col>

          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <h4 class="f-18 f-16-sm f-14-xs f-12-xxs">
              お客様の望むスピード感を重視
            </h4>
            <div
              class="text-left f-11 line17 mt-6 px-2 area_text relative-center w600"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;売買の相手方＝買い手をいち早く見つける方法は
              やはり、多くの不動産事業者（＝仲間）と手分けすることです。クレボでは、お預かりした物件は原則、広告掲載に加えて同業者間のネットワークをフル活用し、迅速に
              情報公開を行います<br /><br />
              &nbsp;&nbsp;&nbsp;&nbsp;「買い手も自社で見つける」いわゆる両手持ちのクローズ施策をとる事業者が多い中、クレボでは他の不動産業者との連携を通じて、数多くの有用情報を共有できるオープン手法こそが、お客様の欲するスピードに応えられるものと確信いたしております
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="relative opacity0" id="point3" ref="sec4">
        <div class="relative-center w1300">
          <div ref="harp3" />
          <h3 class="relative inline-block">
            <img src="@/assets/img/pr.svg" class="absolute pr_img z-1" />
            <div class="relative f-blue fuchi f-24 f-16-sm f-15-xs z-2">
              大切な物件を 魅力ある<br
                class="dis-xs"
              />フレーズで&nbsp;ＰＲします
            </div>
          </h3>
          <div class="point4">
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                <div class="w600 relative-center">
                  <h4 class="f-13-sm f-11-xs">
                    言葉で買い手を惹き付けられる
                    <br class="dis-xxs" />唯一の不動産屋です
                  </h4>
                  <div class="line17 f-11 mt-10 text-left">
                    &nbsp;&nbsp;&nbsp;&nbsp;広告に掲載される物件のイメージは、言葉ひとつで大きく変わります。最低限の情報を淡々と並べただけでは読み手の心には刺さりません。１人でも多くの人に興味を抱いてもらい、「ちょっと見に行ってみようか」と思い立たせる、そんなワンプッシュを添えるひと工夫もまた、クレボが得意とする分野です<br /><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;もちろん、内容にウソや誇張を用いることはありません。その物件のあるがままの姿を魅力たっぷりに紹介させていただきます
                  </div>
                </div>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                <table class="hikaku_tb relative-center">
                  <tr class="bold f-11 f-10-xxs green lighten-5">
                    <td>通常の広告</td>
                    <td>
                      <span class="noto9 f-black fuchi_shadow f-14"
                        >C<span class="f-orange">R</span>EVo </span
                      >&nbsp;の<br class="dis-xxxs" />ワンプッシュ
                    </td>
                  </tr>
                  <tr
                    class="bold f-11 f-10-md f-08-xxs"
                    v-for="t in tbs"
                    :key="t.n"
                  >
                    <td v-html="t.n" />
                    <td v-html="t.c" class="f-orange fuchi_shadow2" />
                  </tr>
                </table>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <ContactBtn />
    </div>

    <div id="buy" />
    <div class="pt-10">
      <h2 class="f-20 f-15-xxs inline-block f-gray">購入をご検討のお客様</h2>
      <br />
      <div
        class="f-12 f-09-xs mt-10 relative-center text-left inline-block px-2"
      >
        大きな買い物の購入を決意し、お金を支払うお客様のサポートには<br
          class="disno-xs"
        />
        不動産専門家としての見識＆良識に加え、広い視野も求められます<br /><br
          class="dis-xs"
        />
        <span class="f-12-xs f-10-xxxs">「案内人の私達でも 住んでみたい」</span
        ><br class="dis-xs" />
        <br class="dis-xs" />
        そう思える物件をご案内いたします
      </div>
      <div
        class="relative-center point f-20 f-16-sm f-14-xs f-11-xxs f-10-xxxs noto7 f-orange w750"
      >
        <img
          src="@/assets/img/point.webp"
          class="point_img absolute filter-shadow"
        />
        <div class="text-left relative">
          不動産サイトに掲載されている物件は<br />
          すべてお取り扱いできます
        </div>
      </div>

      <v-row class="w800 relative-center mt-10 mb-5 text-center">
        <v-col cols="12" class="noto7 f-12 f-10-xxxs">
          目的の物件ボタンを押してください
        </v-col>
        <v-col cols="4">
          <router-link to="#hausing">
            <v-btn
              block
              outlined
              color="teal"
              large
              class="f-11 f-10-xxs select_btn box-shadow"
              >住居用</v-btn
            >
          </router-link>
        </v-col>
        <v-col cols="4">
          <router-link to="#business">
            <v-btn
              block
              outlined
              color="teal"
              large
              class="f-11 f-10-xxs select_btn box-shadow"
              >事業用</v-btn
            >
          </router-link>
        </v-col>
        <v-col cols="4">
          <router-link to="#investment">
            <v-btn
              block
              outlined
              color="teal"
              large
              class="f-11 f-10-xxs select_btn box-shadow"
              >投資用</v-btn
            >
          </router-link>
        </v-col>
      </v-row>

      <div class="relative">
        <div id="hausing" />
        <div class="opacity0" ref="sec5">
          <table
            class="mt-16 mb-10 bukken_ver noto7 f-red f-20 relative-center inline-block"
          >
            <tr>
              <td>
                <img
                  src="@/assets/img/buy1.svg"
                  class="buy_img pr-2 relative"
                />
              </td>
              <td>住居用物件 <span class="f-gray">編</span></td>
            </tr>
          </table>

          <v-row class="w1200 relative-center">
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="12"
              sm="12"
              class="text-left inline-block relative-center w550 buy_text"
            >
              <div class="text-center mb-10">
                <div class="f-18 f-14-xxs f-12-xxxs pl-4">
                  購入後あるある No.１とは？
                </div>
              </div>

              <div class="f-blue f-11 f-10-xxs f-09-xxxs bold">
                それは 物件に対する不満ではありません<br />
                こんなはずではなかった、ご近所との関係です
              </div>
              <br />
              引越し後、隣人に挨拶をしてもプイと横を向いたまま知らんぷり。やれやれ、この先、何十年も暮らしていくというのに……悲しい気持ちになってしまいます<br /><br />
              まさに
              “あるある”の１コマなのに、多くの不動産事業者は、隣人に関してはどうにもできませんと説明して終わりのようですが、本当にそうでしょうか？<br /><br />
              クレボでは「お隣さんがどういう人なのか気になる」<br />
              というお客様の要望があれば、ご購入の前に隣人宅を訪ね、実際に会話をし、確認をさせていただきます。<br /><br />
              その結果、ご購入を見送りとされても問題はございません。やはり、大きな買い物をする以上は、物件の良し悪しだけでなく周囲の環境も含めてご納得いただく必要があると考えています
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12">
              <div class="relative-center w588 navi2">
                <img src="@/assets/img/navi2.jpg" class="navi_img w350" />
                <img src="@/assets/img/fuki1.svg" class="absolute fuki1" />
                <img src="@/assets/img/fuki2.svg" class="absolute fuki2" />
                <div class="fuchi_shadow bold f-blue f-09-xs">
                  引越後、ご近所の挨拶まわりが苦手な方は、同行いたします
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <div id="business" />
        <div class="opacity0" ref="sec6">
          <table
            class="mt-16 mb-10 bukken_ver noto7 f-red f-20 relative-center inline-block"
          >
            <tr>
              <td>
                <img
                  src="@/assets/img/buy2.svg"
                  class="buy_img pr-2 relative"
                />
              </td>
              <td>事業用物件 <span class="f-gray">編</span></td>
            </tr>
          </table>

          <v-row class="w1200 relative-center">
            <v-col cols="12" xl="6" lg="6" md="12" sm="12">
              <div class="relative-center w588">
                <img src="@/assets/img/tintai1.jpg" class="navi_img w350" />
                <img src="@/assets/img/fuki7.svg" class="absolute fuki7_1" />
                <img src="@/assets/img/fuki8.svg" class="absolute fuki8_1" />
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="12"
              sm="12"
              class="text-left inline-block relative-center w550 buy_text"
            >
              <div class="text-center mb-10">
                <div class="f-16 f-13-xs pl-4">
                  購入はもちろん、賃貸物件も承っています
                </div>
              </div>

              <div class="f-blue f-11 f-10-xxxs bold">
                購入側の情報量 ＜ 売却側の情報量<br />
                この情報不均衡によるリスクを軽減します
              </div>
              <br />
              不動産物件に関して、売主が知っていることの全てを買主が知ろうとするのは至難の業です。情報量に差がある分、買主は不利な立場を強いられます。にもかかわらず、現状有姿渡しの契約が多いのもまた現状。
              <br /><br />
              不動産の購入には一にも二にも「慎重」が求められます。ここは、リスクマネージャーのクレボにお任せください。
              <br /><br />
              不動産はとても大きな買い物になります。その割には物件情報が不足していると感じます。購入者様が迷いなく意思決定できるよう、ひとつでも多くの判断材料をお届けしたいと思います
            </v-col>
          </v-row>
        </div>

        <div id="investment" />
        <div class="opacity0" ref="sec7">
          <table
            class="mt-16 mb-10 bukken_ver noto7 f-red f-20 relative-center inline-block"
          >
            <tr>
              <td>
                <img
                  src="@/assets/img/buy3.svg"
                  class="buy_img pr-2 relative"
                />
              </td>
              <td>投資用物件 <span class="f-gray">編</span></td>
            </tr>
          </table>

          <v-row class="w1200 relative-center">
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="12"
              sm="12"
              class="text-left inline-block relative-center w550 buy_text"
            >
              <div class="text-center mb-10">
                <div class="f-16 f-13-xxs f-12-xxxs pl-4">
                  強みはズバリ、ネットワーク
                </div>
              </div>

              <div class="f-blue f-11 f-10-xxs f-09-xxxs bold">
                他社様とご併用いいただいて構いません<br />
                まずは、ご希望のオーダーをお聞かせください
              </div>
              <br />
              ご予算、地域、物件の種類、築年数、構造、云々<br />
              大まかでも、細かくても、問題ございません
              ご相談をいただけるだけでも深く感謝いたします<br /><br />

              物件探しは、不動産屋としての腕前が存分に問われるところです。お客様の想定を超えるハイスペックな物件をスピード調達できるよう努めてまいります<br /><br />

              クレボでは、同業者および不動産関連業者とのネットワークによって物件情報のスケールアップを図っています。力になってもらえる仕事仲間を地道に増やしていくこともまた、お客様に応えるために欠かせない重要なミッションといえます
              <br /><br />
              ウェブ上には掲載されていない掘り出し情報を含め、お客様にベストマッチした物件を、必ずやお探しいたします
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12">
              <div class="relative-center w588 net1">
                <img src="@/assets/img/net1.jpg" class="navi_img w350" />
                <img src="@/assets/img/fuki5.svg" class="absolute fuki5_1" />
                <img src="@/assets/img/fuki6.svg" class="absolute fuki6_1" />
                <div class="fuchi_shadow bold">
                  今すぐに、とお考えでない方も<br />
                  お気軽にエントリーください
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <ContactBtn />

        <div class="line_house text-right">
          <img src="@/assets/img/line_house.svg" class="relative" />
        </div>

        <div class="relative overflow-hidden">
          <img
            src="@/assets/img/bg_house.webp"
            class="absolute b-0 l-0 w-100 bg_house_img"
          />
          <div class="absolute b-0 l-0 bg_house_filter w-100 h-100" />

          <div id="manage" />
          <h2
            class="f-20 f-14-xxs f-12-xxxs inline-block f-gray mt-10 mb-10 relative"
          >
            管理の委託をご検討のお客様
          </h2>
          <br />

          <div
            class="card card1 box-shadow inline-block relative-center mb-14 opacity0"
            ref="sec8_1"
          >
            <div class="f-16 f-12-xs f-11-xxs f-blue mb-5">
              入居者を 満足させよう ホトトギス
            </div>
            <div class="management_bar1 management_bar box-shadow absolute" />
            <v-row>
              <v-col cols="12" xl="8" lg="8" md="8" sm="8" class="text-center">
                <div class="text-left inline-block f-09-xxs">
                  <div class="line18 mb-5">
                    「管理人が親切で、笑顔で挨拶してくれる」<br />
                    「施設の周囲がいつも清潔に保たれている」<br />
                    「トラブル発生時の対応が、丁寧で素早い」<br />
                  </div>
                  管理の品質は、物件の立地や利便性と並んで不動産が優良物件と<br
                    class="disno-sm"
                  />
                  なるための必須条件です<br />
                  居心地が良ければ長く入居し続けようとする分、経営は安定します
                </div>
              </v-col>
              <v-col cols="4" class="disno-xs">
                <img
                  src="@/assets/img/management1.webp"
                  class="management1_img"
                />
              </v-col>
            </v-row>
          </div>
          <br />

          <div
            class="card card2 box-shadow inline-block relative-center mb-14 opacity0"
            ref="sec8_2"
          >
            <div class="f-16 f-12-xs f-11-xxs f-green mb-5">
              クレボは ‟優形（やさがた）<br class="dis-xxs" />不動産” です
            </div>
            <div class="management_bar2 management_bar box-shadow absolute" />
            <v-row>
              <v-col cols="12" xl="8" lg="8" md="8" sm="8" class="text-center">
                <div class="text-left inline-block f-09-xxs">
                  やさがたとは？ 強面（こわもて）の真逆です<br />
                  クレボは、いつでも&nbsp;&nbsp;どこでも&nbsp;&nbsp;誰とでも<br
                    class="disno-sm"
                  />
                  スピード＆スマイルを掲げる接客サービス業の従事者です<br /><br />
                  創業以来、人と人の出会いと対話を通じて事業活動に<br
                    class="disno-sm"
                  />
                  勤しんでまいりました<br />
                  不動産管理の品質アップを図りたいとオーナー様は<br
                    class="disno-sm"
                  />
                  ご相談ください
                </div>
              </v-col>
              <v-col cols="4" class="disno-xs">
                <img
                  src="@/assets/img/management2.webp"
                  class="management2_img"
                />
              </v-col>
            </v-row>
          </div>
          <br />

          <div
            class="card card3 box-shadow inline-block relative-center mb-14 opacity0"
            ref="sec8_3"
          >
            <div class="f-16 f-12-xs f-11-xxs f-orange mb-5">
              入居者を 入れてみせよう ホトトギス
            </div>
            <div class="management_bar3 management_bar box-shadow absolute" />
            <v-row>
              <v-col cols="12" xl="8" lg="8" md="8" sm="8" class="text-center">
                <div class="text-left inline-block f-09-xxs">
                  <div class="line18 mb-5">
                    「もうずっと空室が続いている……」<br />
                    「もはや賃料を下げるしかないのか……」
                  </div>
                  こうしたお悩みを抱えるオーナー様は是非一度<br
                    class="disno-xxs"
                  />
                  クレボにご相談ください
                </div>
              </v-col>
              <v-col cols="4" class="disno-xs">
                <img
                  src="@/assets/img/management3.webp"
                  class="management3_img"
                />
              </v-col>
            </v-row>
          </div>
          <br />

          <div
            class="card card4 box-shadow inline-block relative-center mb-14 opacity0"
            ref="sec8_4"
          >
            <div class="f-16 f-12-xs f-11-xxs f-red mb-5">
              クレボが請け負う<br class="dis-xxs" />不動産の管理業務とは
            </div>
            <div class="management_bar4 management_bar box-shadow absolute" />
            <v-row>
              <v-col cols="12" xl="8" lg="8" md="8" sm="8" class="text-center">
                <div class="text-left inline-block f-09-xxs">
                  単に入居者や物件の保全にとどまらず、管理物件の賃料を<br
                    class="disno-sm"
                  />
                  最大化させる収益獲得ミッションも請け負うものと銘じ<br
                    class="disno-sm"
                  />
                  物件の特性に応じて様々なマネジメントを講じています<br />
                  クレボは“考えに考え &nbsp;&nbsp;考えを巡らす”
                  不動産事業者です<br /><br />
                  築年数が経過していたり、立地の悪い物件でもあっても<br
                    class="disno-sm"
                  />
                  ひと工夫を凝らすことで人気物件へと生まれ変わる余地は<br
                    class="disno-sm"
                  />
                  十分にあり、入居率アップに向けた様々な利回り改善策を<br
                    class="disno-sm"
                  />
                  ご提案いたします
                </div>
              </v-col>
              <v-col cols="4" class="disno-xs">
                <img
                  src="@/assets/img/management4.webp"
                  class="management4_img"
                />
              </v-col>
            </v-row>
          </div>
          <br />
          <ContactBtn />
        </div>
      </div>
    </div>

    <div id="use" />
    <div class="application relative">
      <h2
        class="f-20 f-18-xs f-13-xxs f-12-xxxs inline-block f-gray mt-10 mb-16 relative"
      >
        不動産の活用をご検討のお客様
      </h2>
      <div class="opacity0" ref="sec9">
        <v-row class="w1200 relative-center">
          <v-col cols="12" xl="7" lg="7" md="7">
            <div
              class="application_text text-left f-11 f-10-md line17 inline-block"
            >
              たとえば今、静かに眠っている未利用の不動産があるならば<br
                class="disno-xs"
              />
              是非とも&nbsp;&nbsp;クレボにご一報ください<br />
              あるいは、収益性が悪化して長く解決策が見当たらない不動産を<br
                class="disno-xs"
              />
              お持ちの場合もまた、クレボにご相談ください<br /><br />
              未利用不動産の再活用や、不採算物件の収益改善に向けた<br
                class="disno-xs"
              />
              最適化プランニングは、まさに ‟考えに考える不動産” クレボの<br
                class="disno-xs"
              />
              得意分野に他なりません<br />
              見向きもされない物件ほど、クレボは燃えに燃えますので<br
                class="disno-xs"
              />
              「無理だろう」と諦めずに、ご連絡いただければ嬉しい限りです
            </div>
          </v-col>
          <v-col cols="12" xl="5" lg="5" md="5">
            <div class="application1 flex-center">
              <img src="@/assets/img/application1.webp" />
            </div>
          </v-col>
        </v-row>

        <v-row class="w1200 relative-center mt-16">
          <v-col
            cols="12"
            xl="5"
            lg="5"
            md="5"
            order="2"
            order-lg="1"
            order-xl="1"
            order-md="1"
          >
            <div class="application1 flex-center">
              <img src="@/assets/img/application2.webp" />
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="7"
            lg="7"
            md="7"
            order="1"
            order-lg="2"
            order-xl="2"
            order-md="2"
          >
            <div class="f-16 f-12-xs f-blue mb-10 noto7 fuchi">
              収益力をアップさせる<br class="dis-xxs" />テナント・ミックス
            </div>
            <div
              class="application_text text-left f-11 f-10-md line17 inline-block"
            >
              たとえば、立地が悪くて入居が見込めない物件の賃料を<br
                class="disno-xs"
              />
              下げたからといって、必ずしも入居者が現れるとは限りません<br />
              適正賃料は、立地よりも物件の持つ収益力・集客力に左右されます<br /><br />

              収益性を高めるテナント・ミックスに基づく誘致を行ったり<br
                class="disno-xs"
              />
              他の物件にない独自の価値を加えて物件の魅力を増すことで<br
                class="disno-xs"
              />
              眠れる不動産の息を吹き返します<br />
            </div>
          </v-col>
        </v-row>
      </div>

      <div id="etc" />
      <h2
        class="f-20 f-14-xxs f-13-xxxs inline-block f-gray mt-10 mb-16 relative mt-10"
      >
        その他&nbsp;&nbsp;不動産に関するご相談
      </h2>
      <div class="opacity0" ref="sec10">
        <v-row class="w1200 relative-center">
          <v-col cols="12" xl="7" lg="7" md="7" class="px-0">
            <div
              class="application_text text-left f-11 f-10-md line17 inline-block"
            >
              クレボでは不動産に関するあらゆるご相談を<br class="disno-xs" />
              随時、承っております<br /><br />
              <div class="line18">
                「今すぐに売買というわけではないが……」<br />
                「相続への備えを聞いておきたい」<br />
                「登記の内容を変更したい」<br />
                「節税対策として検討したい……」&nbsp;&nbsp;etc <br />
              </div>
              <br />
              どんな内容でも構いません<br />
              クレボビル内には、<router-link to="/#staff"
                >実務の専門家 </router-link
              >が常駐していますので<br class="disno-xs" />
              あらゆるご相談に応じることができます
            </div>
          </v-col>
          <v-col cols="12" xl="5" lg="5" md="5">
            <div class="application3 flex-center">
              <img src="@/assets/img/application3.webp" />
            </div>
          </v-col>
        </v-row>
      </div>
      <ContactBtn />
    </div>
  </div>
</template>

<script>
import ContactBtn from "@/components/parts/contactBtn.vue";
import Scroll from "@/mixins/scroll";
import d from "@/d";
export default {
  mixins: [Scroll],
  data() {
    return {
      qas: [
        {
          title: "たとえば…… <br />こんなテンションでも&nbsp;？",
          q: "売る気なんてサラサラないよ、ちょっと確認したいだけ",
          a: "全く問題ありません。<br />クレボにとっても学びの機会を得ることができますので、お取引の有無に関わらず歓迎いたします",
        },
        {
          title: "ならば…… <br />こんなテンションは&nbsp;？",
          q: "今すぐにとは考えてないよ、無料査定だから依頼したのさ",
          a: "大丈夫です、何ら問題ありません。<br />査定の精度に関しては自信がございますので是非、クレボを末席に加えてくださいませ。他社の査定金額と比較する際のモノサシとして活用いただくだけでも構いません",
        },
      ],
      shows_q: [0, 0],
      shows_a: [0, 0],
      houses: new Array(17).fill(0),
      sec1: 1,
      sec2: 1,
      sec3: 1,
      sec4: 1,
      sec5: 1,
      sec6: 1,
      sec7: 1,
      sec8: 1,
      sec9: 1,
      sec10: 1,
      papers: [0, 0],
      tbs: [
        {
          n: "駐車場２台可",
          c: "駐車場は嬉しい並列２台分<br />毎日の入替ストレスがゼロ",
        },
        {
          n: "閑静な住宅街",
          c: "車の往来は近隣住民のみ<br>本物の「閑静な住宅」です",
        },
        {
          n: "&#9675;&#9675;駅徒歩10分",
          c: "坂道のない平坦コースで<br>駅まで10分ウォーキング",
        },
      ],
      m0: 0,
      m1: 0,
      m2: 0,
      m3: 0,
    };
  },
  components: {
    ContactBtn,
  },
  created() {
    for (let i = 0; i < this.houses.length; i++) {
      this.houses[i] = Math.floor(Math.random() * 3);
    }
    this.houses.splice();

    if (window.innerWidth < 960) {
      this.papers = [1, 1];
    }
  },
  async mounted() {
    await d.sleep(500);
    const he = this.$refs.area.clientHeight + 300;
    document.documentElement.style.setProperty("--he", `-${he}px`);
    this.scroll01();
    this.scroll02();
    this.scroll03();
    this.scroll04();
    this.scroll05();
    this.scroll06();
    this.scroll07();
    this.scroll08();
    this.scroll09();
    this.scroll10();
  },
  methods: {
    async scroll01() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.houses.getBoundingClientRect().top * 2 &&
        this.sec1
      ) {
        this.sec1 = 0;
        for (let i = 0; i < this.houses.length * 4; i++) {
          this.houses[Math.floor(Math.random() * 17)] = 1;
          this.houses.splice();
          await d.sleep(1000);
        }
      }
    },

    async scroll02() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec2.getBoundingClientRect().top * 3 &&
        this.sec2
      ) {
        this.sec2 = 0;
        this.$refs.sec2.classList.add("bottom-up");
      }
    },
    async scroll03() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec3.getBoundingClientRect().top * 3 &&
        this.sec3
      ) {
        this.sec3 = 0;
        this.$refs.sec3.classList.add("bottom-up");
      }
    },
    async scroll04() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec4.getBoundingClientRect().top * 4 &&
        this.sec4
      ) {
        this.sec4 = 0;
        this.$refs.sec4.classList.add("bottom-up");
      }
    },
    async scroll05() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec5.getBoundingClientRect().top * 5 &&
        this.sec5
      ) {
        this.sec5 = 0;
        this.$refs.sec5.classList.add("bottom-up");
      }
    },
    async scroll06() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec6.getBoundingClientRect().top * 6 &&
        this.sec6
      ) {
        this.sec6 = 0;
        this.$refs.sec6.classList.add("bottom-up");
      }
    },
    async scroll07() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec7.getBoundingClientRect().top * 6 &&
        this.sec7
      ) {
        this.sec7 = 0;
        this.$refs.sec7.classList.add("bottom-up");
      }
    },
    async scroll08() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec8_1.getBoundingClientRect().top * 7 &&
        this.sec8
      ) {
        this.sec7 = 0;
        this.$refs.sec8_1.classList.add("right-in");
        await d.sleep(600);
        this.$refs.sec8_2.classList.add("left-in");
        await d.sleep(450);
        this.$refs.sec8_3.classList.add("right-in");
        await d.sleep(300);
        this.$refs.sec8_4.classList.add("left-in");
      }
    },
    async scroll09() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec9.getBoundingClientRect().top * 7 &&
        this.sec9
      ) {
        this.sec9 = 0;
        this.$refs.sec9.classList.add("bottom-up");
      }
    },
    async scroll10() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.sec10.getBoundingClientRect().top * 7 &&
        this.sec10
      ) {
        this.sec10 = 0;
        this.$refs.sec10.classList.add("bottom-up");
      }
    },
  },
  watch: {
    scroY() {
      this.scroll01();
      this.scroll02();
      this.scroll03();
      this.scroll04();
      this.scroll05();
      this.scroll06();
      this.scroll07();
      this.scroll08();
      this.scroll09();
      this.scroll10();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/sale.scss";
@import "@/assets/sass/components/buy.scss";
@import "@/assets/sass/components/realEstate.scss";
</style>
